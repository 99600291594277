import React from "react"

import GuidesIndex from "../components/guides-index"
import Layout from "../components/layout/layout"


const Guides = () => {
  return (
    <Layout>
      <GuidesIndex />
    </Layout>
  )
}

export default Guides
