import React from "react"
import { Link } from "gatsby"

const GuidesIndex = () => {
  return (
    <div>
      <h1 className="title is-4">1-page guides</h1>
      <p className="is-size-5 my-3">
        <Link to="/to/the-six-fundamental-marketing-strategies">
          The 6 fundamental marketing strategies
        </Link>
      </p>
      <p className="is-size-5 my-3">
        <Link to="/to/social-and-influencer-marketing">
          Social and influencer marketing
        </Link>
      </p>
      <p className="is-size-5 my-3">
        <Link to="/to/seo-strategy-for-marketing">
          SEO strategy for marketing
        </Link>
      </p>
      <p className="is-size-5 my-3">
        <Link to="/to/how-to-set-up-marketing-analytics">
          How to set up marketing analytics
        </Link>
      </p>
      <p className="is-size-5 my-3">
        <Link to="/to/brand-advertising-on-youtube">
          Brand advertising on YouTube
        </Link>
      </p>
      <p className="is-size-5 my-3">
        <Link to="/to/digital-advertising">Digital advertising</Link>
      </p>
      <p className="is-size-5 my-3">
        <Link to="/to/how-tagging-works">How tagging works</Link>
      </p>
      <p className="is-size-5 my-3">
        <Link to="/to/ecommerce-strategy-for-brands">
          eCommerce strategy for brands
        </Link>
      </p>
      <p className="is-size-5 my-3">
        <Link to="/to/recruiting-for-startups">Recruiting for startups</Link>
      </p>
      <p className="is-size-5 my-3">
        <Link to="/to/how-to-be-insightful">How to be insightful</Link>
      </p>
    </div>
  )
}

export default GuidesIndex
